






































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { disciplines } from '@/components/Offer/constants/disciplines.constants';
const OfferGalleryModule = namespace("OfferGalleryModule");
const Auth = namespace("Auth");
import CoordinatesService from '@/services/CoordinatesService';

@Component
export default class OfferGallery extends Vue {
  private loading = false;
  private min_range = 0;
  private max_range = 1000000;

  private ownOffers = false;

  private filter = {
    price : [0, 100000],
    disciplines : [],
    userId: null,
    distanceRange: [null, null, null]
  }

  private search_plz : number | null = null;

    // load enums
  private disciplines = disciplines;

  @Auth.State("user")
  private currentUser!: any;

  @OfferGalleryModule.Action
  private loadOffers!: (filter:any) => Promise<void>;

  @OfferGalleryModule.State('offers')
  private offers!: any;

  async fetchOffers() {
    this.filter.userId = this.ownOffers ? this.currentUser.id : undefined;
    await this.loadOffers(this.filter);
  }

  async mounted() {
    await this.loadOffers(this.filter);
  }

  async onPlzChange() {
    const address = await CoordinatesService.findAddress({zip: this.search_plz});
    this.filter.distanceRange[0] = address.lon;
    this.filter.distanceRange[1] = address.lat;
  } 
}
