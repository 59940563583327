import { apiConfig } from '@/config/api.config';
import axios from 'axios';
import { addFilter } from './filter-helper';

const API_URL = `${apiConfig.host}api/coordinates/`;

class CoordinatesService {
  async findAddress(address: any): Promise<any> {
    let url = API_URL;
    url = addFilter(url, 'street', address.street);
    url = addFilter(url, 'houseNumber', address.houseNumber);
    url = addFilter(url, 'zip', address.zip);
    url = addFilter(url, 'city', address.city);

    const response = await axios.get(url, {
      headers: { client: 'medcomplex' },
    });
    return response.data;
  }
}

export default new CoordinatesService();
